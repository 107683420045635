@charset "UTF-8";
body, html {
  margin: 0;
  padding: 0;
  border: 0;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/lato-v14-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Lato Light"), local("Lato-Light"), url("../fonts/lato-v14-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v14-latin-300.woff2") format("woff2"), url("../fonts/lato-v14-latin-300.woff") format("woff"), url("../fonts/lato-v14-latin-300.ttf") format("truetype");
  /* Safari, Android, iOS */
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/lato-v14-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Lato Regular"), local("Lato-Regular"), url("../fonts/lato-v14-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v14-latin-regular.woff2") format("woff2"), url("../fonts/lato-v14-latin-regular.woff") format("woff"), url("../fonts/lato-v14-latin-regular.ttf") format("truetype");
  /* Safari, Android, iOS */
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/lato-v14-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Lato Bold"), local("Lato-Bold"), url("../fonts/lato-v14-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v14-latin-700.woff2") format("woff2"), url("../fonts/lato-v14-latin-700.woff") format("woff"), url("../fonts/lato-v14-latin-700.ttf") format("truetype");
  /* Safari, Android, iOS */
}

/* lato-900 - latin */
@font-face {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/lato-v14-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Lato Bold"), local("Lato-Bold"), url("../fonts/lato-v14-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v14-latin-900.woff2") format("woff2"), url("../fonts/lato-v14-latin-900.woff") format("woff"), url("../fonts/lato-v14-latin-900.ttf") format("truetype");
  /* Safari, Android, iOS */
}

body.imprint #background #content {
  margin-top: -300px;
  padding-bottom: 80px;
}

body.imprint #background #content #stroh-rechts {
  position: absolute;
  right: 0px;
  top: 300px;
  background-image: url("../img/stroh-rechts.png");
  width: 313px;
  height: 841px;
  background-repeat: no-repeat;
}

body.imprint #background #content strong {
  font-weight: bold;
}

body.imprint #background #content .inner-content {
  display: block;
}

body.imprint #background #content .inner-content #logo-head {
  width: 1100px;
  font-weight: bold;
}

@media (max-width: 600px) {
  body.imprint #background #content .inner-content {
    padding-top: 0px;
  }
}

body.imprint #background #content .inner-content #imprint-content {
  width: 100%;
  color: #007935;
  padding-top: 80px;
  padding-bottom: 40px;
}

body.imprint #background #content .inner-content #imprint-content .headline {
  text-align: left;
}

body.imprint #background #content .inner-content #imprint-content p {
  font-size: 18px;
  font-family: "Lato", sans-serif;
  line-height: 26px;
  text-align: left;
  max-width: 770px;
}

body.imprint #background #content .inner-content #imprint-content p.headline {
  font-size: 30px;
  line-height: 37px;
  font-family: "Lato", sans-serif;
}

body.imprint #background #content .inner-content #imprint-content ul li {
  font-size: 18px;
  line-height: 30px;
}

body.imprint #background #content .inner-content a {
  color: #007935;
}

@media (max-width: 1040px) {
  body.imprint #background #content .inner-content #imprint-content {
    width: 100%;
  }
}

@media (max-width: 600px) {
  body.imprint #background #content .inner-content #imprint-content {
    width: 100%;
  }
}

html {
  height: 100%;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
  font-family: "Lato", sans-serif;
  min-height: 100%;
  height: 100%;
}

body p {
  font-size: 18px;
  line-height: 26px;
}

body strong {
  font-family: "Lato", sans-serif;
  font-weight: normal;
}

body .button {
  padding: 12px 20px;
  background-color: #007935;
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

body .button:hover {
  background-color: #01612B;
}

body a.button-link {
  text-decoration: none;
}

body .standard-button {
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 240px;
  background-color: #007935;
  height: 40px;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
}

body .standard-button:hover {
  background-color: #01612B;
}

body .headlines {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  line-height: 37px;
  color: #007935;
}

body .headlines .dunkelgruen {
  color: #01612B;
}

body #background {
  position: relative;
  background-image: url("../img/bg-pattern.png");
  background-repeat: repeat;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

body #background #bg-shadow {
  position: relative;
  width: 100%;
  background-image: url("../img/bg-pattern-verlauf.png");
  background-repeat: repeat-x;
  height: 300px;
}

body #background #stroh-unten {
  position: absolute;
  bottom: -90px;
  right: 0px;
  margin-bottom: 100px;
}

body #background #stroh-unten #stroh-unten-img {
  width: 448px;
  height: 328px;
  background-image: url("../img/stroh-unten.png");
  background-size: contain;
  background-repeat: no-repeat;
}

body #background #stroh-links {
  position: absolute;
  top: -195px;
  left: 0px;
}

body #background #stroh-links #stroh-links-img {
  position: absolute;
  width: 244px;
  height: 645px;
  background-image: url("../img/stroh-links.png");
  background-size: contain;
  background-repeat: no-repeat;
}

body #background #content {
  position: relative;
  z-index: 900;
  min-height: 100%;
  height: 100%;
  margin-top: -300px;
}

body #background #content #logo-head {
  padding-top: 40px;
  height: 120px;
  display: flex;
  align-items: center;
}

body #background #content #logo-head .logo {
  display: inline;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

body #background #content #logo-head #logo-nord {
  background-image: url("../img/logo-aldi-nord.png");
  width: 114px;
  height: 120px;
  background-size: contain;
}

body #background #content #logo-head #logo-sued {
  margin-left: 20px;
  background-image: url("../img/logo-aldi-sued.png");
  width: 100px;
  height: 120px;
  background-size: contain;
}

body #background #content #logo-head #logo-sued-impressum {
  margin-left: 0px;
  background-image: url("../img/logo-aldi-sued.png");
  width: 100px;
  height: 120px;
  background-size: contain;
}

body #background #content #logo-head #logo-headline {
  margin-left: 40px;
}

body #background #content .inner-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1180px;
  min-height: 500px;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

body #background #content .inner-content .box-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 200px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

body #background #content .inner-content .box-container .container {
  background-color: white;
  border-radius: 10px;
  width: 490px;
  padding: 40px;
  min-width: 360px;
  margin: 10px;
}

body #background #content .inner-content .box-container .container.kriterien {
  padding-bottom: 60px;
}

body #background #content .inner-content .box-container .container .headline {
  width: 100%;
  font-size: 30px;
  line-height: 38px;
  font-weight: bold;
}

body #background #content .inner-content .box-container .container #herkunft {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

body #background #content .inner-content .box-container .container #herkunft p {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

body #background #content .inner-content .box-container .container #herkunft #holder {
  position: relative;
  height: auto;
  display: flex;
  justify-content: flex-start;
  margin-top: auto;
  flex-wrap: wrap;
}

body #background #content .inner-content .box-container .container #herkunft #holder .standard-button {
  display: flex;
  min-width: 240px;
  margin-right: auto;
}

body #background #content .inner-content .box-container .container #herkunft #holder #atc-logo {
  display: flex;
  align-self: flex-end;
  margin-left: auto;
}

body #background #content .inner-content .box-container .container #kriterien {
  position: relative;
}

body #background #content .inner-content .box-container .container #kriterien .stoerer {
  position: absolute;
  right: 0px;
  width: 118px;
  height: 118px;
  background-image: url("../img/stoerer.png");
}

body #background #content .inner-content .box-container .container #kriterien ul {
  width: 100%;
  font-size: 28px;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}

body #background #content .inner-content .box-container .container #kriterien ul li {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 22px;
  line-height: 30px;
  font-weight: normal;
  padding-right: 40px;
}

body #background #content .inner-content .box-container .container #kriterien ul li:before {
  content: "";
  margin-left: -50px;
  margin-top: 0;
  position: absolute;
  background-image: url("../img/check.svg");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: auto;
}

body #background #content .inner-content .box-container .container #kriterien ul li:last-child {
  margin-bottom: 0;
}

body #background #content .inner-content .header-logo {
  width: 288px;
}

body #background #content .inner-content h1 {
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #007935;
  font-family: "Lato-Bold", sans-serif;
  margin-bottom: 10px;
  -webkit-font-smoothing: auto;
  margin-top: 40px;
  /* Die ALDI Verpackungs: */
}

body #background #content .inner-content h1 .subline {
  -webkit-font-smoothing: auto;
}

body #background #content .inner-content p {
  font-size: 16px;
  text-align: center;
  color: #007935;
  font-family: "Lato", sans-serif;
  margin-bottom: 40px;
  max-width: 650px;
  margin-top: 0px;
}

body #background #content .inner-content p.star-hint {
  margin-bottom: 15px;
}

body #background #content .inner-content p.star-hint small {
  font-size: 15px;
  line-height: 1;
  color: #666666;
}

body #background #content .inner-content p.star-hint.hidden {
  opacity: 0;
}

body #background #content .inner-content .logo-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 80px;
}

body #background #content .inner-content .logo-holder .clickable-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 240px;
}

body #background #content .inner-content .logo-holder .clickable-logo#nord {
  margin-right: 40px;
}

body #background #content .inner-content .logo-holder .clickable-logo#sued {
  margin-left: 40px;
}

body #background #content .inner-content .logo-holder .clickable-logo .logo-link {
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
}

body #footer {
  position: relative;
  margin-top: -100px;
  height: 100px;
  color: #ffffff;
  width: 100%;
  z-index: 1000;
  background-color: #666666;
}

body #footer #link-container {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

body #footer #link-container .impressum-link {
  margin-left: 40px;
  margin-right: 40px;
  display: inline;
}

body #footer #link-container .impressum-link a {
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 18px;
}

/*
███    ███  ██████  ██████  ██ ██      ███████
████  ████ ██    ██ ██   ██ ██ ██      ██
██ ████ ██ ██    ██ ██████  ██ ██      █████
██  ██  ██ ██    ██ ██   ██ ██ ██      ██
██      ██  ██████  ██████  ██ ███████ ███████
*/
@media (max-width: 1040px) {
  body #background #stroh-links #stroh-links-img {
    width: 300px;
  }
}

@media (max-width: 940px) {
  body #background {
    overflow: hidden;
  }
  body #background #stroh-links #stroh-links-img {
    width: 300px;
  }
  body #background #stroh-unten {
    width: 100%;
    bottom: 240px;
  }
  body #background #stroh-unten #stroh-unten-img {
    position: absolute;
    right: 0px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (max-width: 700px) {
  body > #background > #content > .inner-content .box-container .container-wrapper {
    min-width: calc(100% - 60px);
    width: 100%;
  }
  body > #background > #content > .inner-content .box-container .container {
    min-width: calc(100% - 60px);
    width: 100%;
    margin-left: -40px;
  }
  body > #background > #content > .inner-content .box-container .container #kriterien .stoerer {
    background-size: 100% 100%;
    width: 98px;
    height: 98px;
    right: -20px;
    top: -90px;
  }
}

@media (max-width: 600px) {
  body #background {
    overflow: hidden;
  }
  body #background #stroh-links #stroh-links-img {
    display: none;
  }
  body #background #content #logo-head {
    padding-top: 20px;
    height: 60px;
  }
  body #background #content #logo-head #logo-nord {
    width: 57px;
    height: 60px;
  }
  body #background #content #logo-head #logo-sued {
    width: 50px;
    height: 60px;
  }
  body #background #content #logo-head #logo-headline {
    display: none;
  }
  body #background #content .inner-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  body #background #content .inner-content .header-logo {
    width: 260px;
  }
  body #background #content .inner-content h1 {
    font-size: 30px;
    line-height: 36px;
  }
  body #background #content .inner-content p {
    font-size: 16px;
    line-height: 22px;
  }
  body #background #content .inner-content .logo-holder {
    flex-direction: column;
  }
  body #background #content .inner-content .logo-holder .clickable-logo#nord {
    margin-right: 0px;
    margin-bottom: 40px;
  }
  body #background #content .inner-content .logo-holder .clickable-logo#sued {
    margin-left: 0px;
  }
  body #footer {
    height: 100px;
    margin-top: -45px;
  }
  body #footer #link-container {
    flex-direction: column;
  }
  body #footer #link-container .impressum-link {
    margin: 7px;
  }
}

@media (min-width: 1260px) {
  body #background #content .inner-content .box-container {
    flex-direction: row;
    align-items: stretch;
  }
  body #background #content .inner-content .box-container .container-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    flex-direction: column;
  }
  body #background #content .inner-content .box-container .container {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
  body #background #content .inner-content .box-container .container #herkunft {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    height: auto;
  }
  body #background #content .inner-content .box-container .container #kriterien {
    width: 100%;
  }
}

/*# sourceMappingURL=style.min.css.map */